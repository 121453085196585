import React, { useState } from "react";

import { LoadingMessage } from "../../../components";
import { Dashboard, MerchantHeader } from "../components";

const Scene = () => {
  const [loading, setLoading] = useState();

  if (loading) {
    return (
      <LoadingMessage message="Please wait while we fetch your transactions.." />
    );
  }

  return (
    <>
      <MerchantHeader />
      <Dashboard setLoading={setLoading} />
    </>
  );
};

export default Scene;
